exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-contact-report-a-vulnerability-tsx": () => import("./../../../src/pages/contact/report-a-vulnerability.tsx" /* webpackChunkName: "component---src-pages-contact-report-a-vulnerability-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logo-user-manual-tsx": () => import("./../../../src/pages/logo-user-manual.tsx" /* webpackChunkName: "component---src-pages-logo-user-manual-tsx" */),
  "component---src-pages-management-systems-certificates-tsx": () => import("./../../../src/pages/management-systems-certificates.tsx" /* webpackChunkName: "component---src-pages-management-systems-certificates-tsx" */),
  "component---src-pages-our-partners-tsx": () => import("./../../../src/pages/our-partners.tsx" /* webpackChunkName: "component---src-pages-our-partners-tsx" */),
  "component---src-pages-our-references-tsx": () => import("./../../../src/pages/our-references.tsx" /* webpackChunkName: "component---src-pages-our-references-tsx" */),
  "component---src-pages-people-and-culture-tsx": () => import("./../../../src/pages/people-and-culture.tsx" /* webpackChunkName: "component---src-pages-people-and-culture-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-products-academy-tsx": () => import("./../../../src/pages/products/academy.tsx" /* webpackChunkName: "component---src-pages-products-academy-tsx" */),
  "component---src-pages-products-cloud-hsm-tsx": () => import("./../../../src/pages/products/cloud-hsm.tsx" /* webpackChunkName: "component---src-pages-products-cloud-hsm-tsx" */),
  "component---src-pages-products-cryptaway-cryptaway-egitim-videosu-tsx": () => import("./../../../src/pages/products/cryptaway/cryptaway-egitim-videosu.tsx" /* webpackChunkName: "component---src-pages-products-cryptaway-cryptaway-egitim-videosu-tsx" */),
  "component---src-pages-products-cryptaway-cryptaway-training-video-tsx": () => import("./../../../src/pages/products/cryptaway/cryptaway-training-video.tsx" /* webpackChunkName: "component---src-pages-products-cryptaway-cryptaway-training-video-tsx" */),
  "component---src-pages-products-cryptaway-index-tsx": () => import("./../../../src/pages/products/cryptaway/index.tsx" /* webpackChunkName: "component---src-pages-products-cryptaway-index-tsx" */),
  "component---src-pages-products-endcrypt-tsx": () => import("./../../../src/pages/products/endcrypt.tsx" /* webpackChunkName: "component---src-pages-products-endcrypt-tsx" */),
  "component---src-pages-products-procrypt-compliance-and-certifications-common-criteria-tsx": () => import("./../../../src/pages/products/procrypt/compliance-and-certifications/common-criteria.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-compliance-and-certifications-common-criteria-tsx" */),
  "component---src-pages-products-procrypt-compliance-and-certifications-fips-tsx": () => import("./../../../src/pages/products/procrypt/compliance-and-certifications/fips.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-compliance-and-certifications-fips-tsx" */),
  "component---src-pages-products-procrypt-compliance-and-certifications-gdpr-tsx": () => import("./../../../src/pages/products/procrypt/compliance-and-certifications/gdpr.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-compliance-and-certifications-gdpr-tsx" */),
  "component---src-pages-products-procrypt-compliance-and-certifications-index-tsx": () => import("./../../../src/pages/products/procrypt/compliance-and-certifications/index.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-compliance-and-certifications-index-tsx" */),
  "component---src-pages-products-procrypt-compliance-and-certifications-pci-compliance-standards-tsx": () => import("./../../../src/pages/products/procrypt/compliance-and-certifications/pci-compliance-standards.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-compliance-and-certifications-pci-compliance-standards-tsx" */),
  "component---src-pages-products-procrypt-index-tsx": () => import("./../../../src/pages/products/procrypt/index.tsx" /* webpackChunkName: "component---src-pages-products-procrypt-index-tsx" */),
  "component---src-pages-request-a-demo-tsx": () => import("./../../../src/pages/request-a-demo.tsx" /* webpackChunkName: "component---src-pages-request-a-demo-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-templates-awards-tsx": () => import("./../../../src/templates/awards.tsx" /* webpackChunkName: "component---src-templates-awards-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-resources-post-tsx": () => import("./../../../src/templates/resources-post.tsx" /* webpackChunkName: "component---src-templates-resources-post-tsx" */),
  "component---src-templates-resources-tsx": () => import("./../../../src/templates/resources.tsx" /* webpackChunkName: "component---src-templates-resources-tsx" */),
  "component---src-templates-solutions-page-tsx": () => import("./../../../src/templates/solutions-page.tsx" /* webpackChunkName: "component---src-templates-solutions-page-tsx" */),
  "slice---src-components-social-media-tsx": () => import("./../../../src/components/SocialMedia.tsx" /* webpackChunkName: "slice---src-components-social-media-tsx" */)
}

